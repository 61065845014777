import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { Section, CardBase } from 'components/organisms';
import pagedata from "content/pages/privacy.yaml";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    '& .description-cta__button-group': {
      flexWrap: 'nowrap',
    },
  },
  divider: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5, 0),
    },
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  cardHighlighted: {
    background: theme.palette.primary.dark,
  },
  textWhite: {
    color: 'white',
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <Section className={classes.pagePaddingTop}>
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <SectionHeader
              title={pagedata.title}
              subtitle={pagedata.subtitle}
              align="left"
              titleProps={{
                className: classes.fontWeightBold,
                color: 'textPrimary',
              }}
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              //ctaGroup={[(
              //<Button color="primary" variant="outlined">Print</Button>
              //),
              //]}
            />
          {pagedata.body.map((section) => (
            <>
            <SectionHeader
                    title={section.title}
                    align="left"
                    titleProps={{
                      className: classes.fontWeightBold,
                      color: 'textPrimary',
                    }}
                    subtitleProps={{
                      variant: 'body1',
                      color: 'textPrimary',
                    }}
                    disableGutter
                  />
                  <Typography variant="body1" color="textPrimary">
                    {section.content.map((line) => {
                    return [<p>{line}</p>, <br />]; //key={key}
                  })}</Typography>
            </>
          ))}		  
		  </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={isMd ? 4 : 2} direction="column">
              <Grid item xs={12}>
                <CardBase withShadow className={classes.cardHighlighted}>
                  <SectionHeader
                    title={pagedata.callout.title}
                    subtitle={pagedata.callout.subtitle}
                    ctaGroup={[<Button href="/contact" variant="contained">{pagedata.callout.buttonText}</Button>]}
                    disableGutter
                    align="left"
                    titleProps={{
                      variant: 'subtitle1',
                      className: clsx(
                        classes.textWhite,
                        classes.fontWeightBold,
                      ),
                    }}
                    subtitleProps={{
                      variant: 'body2',
                      className: classes.textWhite,
                    }}
                  />
                </CardBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Section>
      <Divider />
    </div>
  );
};

export default PrivacyPolicy;
