import React from 'react';
import PrivacyPolicy from 'views/PrivacyPolicy';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { Helmet } from 'react-helmet';

const title = 'Privacy Policy';
 
const PrivacyPolicyPage = () => {
return (
    <>
    <Helmet
      defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
      title={title}
      titleTemplate="%s | WindRate.com"
    />    
    <WithLayout
    component={PrivacyPolicy}
    layout={Main}
    />
    </>
)
};
 
 export default PrivacyPolicyPage;
 